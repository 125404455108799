import React, {useEffect, useContext, useRef, useState} from 'react'
import {Main} from './style'
import {
    Button,
    Form,
    ButtonContainer,
    Box,
    FormControl,
    InputLabel,
    FormHelperText
} from 'component/form/styled'
import WidgetContext from 'component/widget/context'
import Rating from '@material-ui/lab/Rating';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {postMessage} from "../../api/message";
import {postNPS} from "../../api/nps";

const NPS = () => {
    const {chat} = useContext(WidgetContext)
    const [solicitation, setSolicitation] = useState(null)
    const [form, setForm] = useState(false)
    useEffect(() => {
        if (solicitation) {
            chat.nps.questions.map(question => {
                setForm(prevState => ({...prevState, [`question-${question.id}`]: {value: '', error: ''}}))
            })
        }
    }, [solicitation])

    const handleChange = (e) => {
        const {name, type} = e.target
        const value = type === 'checkbox' ? e.target.checked : e.target.value;
        setForm(prevState => ({
            ...prevState,
            [name]: {...prevState[name], value}
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        postNPS({
            "id_protocol": chat.id,
            "answers":Object.keys(form).reduce((prev, curr) => {
                prev.push({"question_id": Number(curr.split('-')[1]), "answer": form[curr].value})
                return prev
            }, [])
        }).then(res => {
            chat.setChat({closed: true})
        }).catch(err => console.log(err))
    }
    const handleDoNotRespond = async () => {
        const formData = new FormData()
        formData.append('message', 'Não')
        formData.append('id_protocol', chat.id)

        let message = await postMessage(formData)
    }
    return <Main>
        {typeof solicitation === 'boolean' ? <>
            {solicitation ? <Form onSubmit={handleSubmit} className="form_container">
                <div>
                    {form && chat.nps.questions.map(question =>
                        <FormControl classes={{root: 'nps'}} color="secondary">
                            <InputLabel shrink={true}
                                        htmlFor={`question-${question.id}`}>{question.question.replace('(Nota de 0 a 5)', '')}</InputLabel>
                            {question.type === '1' ? <Rating
                                name={`question-${question.id}`}
                                value={form[`question-${question.id}`].value}
                                onChange={handleChange}
                            /> : question.type === '3' &&
                                <OutlinedInput multiline={true} rows={4} maxrows={4} name={`question-${question.id}`}
                                               id={`question-${question.id}`}
                                               error={form[`question-${question.id}`].error.trim() !== ''}
                                               onChange={handleChange}
                                               value={form[`question-${question.id}`].value}/>}
                            <FormHelperText
                                error={form[`question-${question.id}`].error.trim() !== ''}>{form[`question-${question.id}`].error}</FormHelperText>
                        </FormControl>)}
                </div>
                <Button className="confirm" type="submit">Enviar</Button>
            </Form> : <></>}
        </> : <>
            <Box>
                <p className="title">{chat.nps.solicitation}</p>
            </Box>
            <Form>
                <ButtonContainer>
                    <Button type="button" className="exit" onClick={handleDoNotRespond}>Não</Button>
                    <Button type="button" className="confirm" onClick={() => setSolicitation(true)}>Sim</Button>
                </ButtonContainer>
            </Form>
        </>}
    </Main>
}

export default NPS