import styled from 'styled-components'

export const FilesContainer = styled.div`
    color: ${props => props.theme.bg};
    display: flex;
    padding: 0 1rem;
    flex-wrap: wrap;
    >div {
        display: flex;
        flex-direction: column;
        width: 7.5rem;
        margin-right: 1rem;
        margin-top: .5rem;
        >div {
            &:first-child{
                background: rgba(77,188,172,0.5);
                color: ${props => props.theme.bg};
                height: 40px;
                font-size: 1.2rem;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            &:last-child{
                background-color: ${props => props.theme.bg};
                color: ${props => props.theme.text};
                display: flex;
                padding: .35rem;
                justify-content: space-between;
                > p {
                    max-width: 80%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                svg {
                    width: 1rem;
                    height: 1rem;
                    cursor: pointer;
                    stroke: ${props => props.theme.text};
                }
            }
        }
    }
    svg {
        stroke: ${props => props.theme.error};
    }
`
