import styled,{keyframes} from 'styled-components'
import Scrollbars from 'react-custom-scrollbars'
const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    height: calc(100% - 54px);
    max-height: calc(100% - 54px);
    min-height: 494px;
    font-size: 14px;
    color: #000000;
    background-color: #ffffff;
    justify-content: space-between;
`