import styled from "styled-components";

import TextFieldStyle from '@material-ui/core/TextField';
import FormControlStyle from '@material-ui/core/FormControl';
import RadioGroupStyle from '@material-ui/core/RadioGroup';
import InputLabelStyle from '@material-ui/core/InputLabel';
import FormLabelStyle from '@material-ui/core/FormLabel';
import FormHelperTextStyle from '@material-ui/core/FormHelperText';
import SelectStyle from '@material-ui/core/Select';
import OutlinedInputStyle from '@material-ui/core/OutlinedInput';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    max-width: 320px;
    max-height: calc(548px - 54px);
    min-height: calc(548px - 54px);
    z-index: 2;
    justify-content: space-between;
    flex-direction: column;
    background-color: #FFF;
    @media(max-width: 500px) {
       max-width: unset;
       max-height: calc(100% - 54px);
    }
    
    
`

export const FormControl = styled(FormControlStyle)`
    && { 
        height: 40px;
        margin: 26px 16px 0;
        &.phone {
            width: 50%;
        }
        &.select {
            height: fit-content;
            margin-top: 27px;
            label {
                left: -14px;
                top: -11px;
            }
        }
        &.nps {
            label {
                width: 133.2%;
                &+span {
                    width: fit-content;
                }
            }
        }
    }
`
export const Select = styled(SelectStyle)`
    && {
        max-height: 40px;
        >div {
            font-size: 14px;
            padding: 10px 14px;
        }
    }
`
export const InputLabel = styled(InputLabelStyle)`
    && {
        top: -18px;
    }
`
export const OutlinedInput = styled(OutlinedInputStyle)`
    && {
        min-height: 40px;
        input {
            font-size: 14px;
            padding: 12px 14px;
        }
    }
`
export const Form = styled.form`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    border-radius: 0 0 8px 8px;
    &.form_container {
        height: 100%;
        >div {
           display: flex;
           flex-direction: column;
        }
    }
    p {
        
        &.title {
            padding: 24px 16px;
            margin-bottom: 0;
            font-weight: 600;
        }
        color: #000000;
        font-weight: bold;
        margin: 0 0 24px;
    }
    .label {
        font-weight: bold;
        color: #074F70;
    }
    
    >button {
        margin-top: 24px;
        padding: 16px 0;
    }
`

export const Button = styled.button`
    padding: 8px 16px;
    border: 0;
    cursor: pointer;
    font-weight: 500;
    background-color: #FFFFFF;
    color: #464E7E;
    outline: none;
    font-size: 14px;
    width: fit-content;
    &.confirm {
        background: #5489A3;
        color: #FFFFFF;
        width: 100%;
    }
    &.exit {
        &:hover {
            background: #464E7E;
            color: #ffffff;
        }
    }
    &:disabled {
        background: #E4E6E6;
        color: #63666A;
        cursor: not-allowed;
    }
    
    &.back {
        align-self: flex-start;
        margin: 24px 32px 32px;
    }
    
    &.backDepartment {
        margin: 8px;
        align-self: flex-start;
        cursor: pointer;
        padding: 8px;
            font-size: 12px;
        &+p.title {
            padding: 5px 16px 16px;
        }
        svg {
            height: 10px;
            margin-right: 5px;
        }
    }
`


export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #5489A3;;
    flex-wrap: wrap;
    padding: 0;
    > ${Button} {
        width: 50%;
        padding: 16px 38px;
    }
`


export const Box = styled.div`
    font-size: 14px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px 0;
    >img {
        margin-bottom: 32px;
        height: 80px;
    }
    
    >p {
        margin: 0;
        &.title {
            color: #000000;
            font-weight: 600;
            margin-bottom: 8px;
            font-size: 15px;
        }
        &.confirm {
            color: #000000;
            margin-top: 16px;
        }
        width: 100%;
        color: #63666A;
        &+p{
            margin-top: 8px;
        }
    }
`

export const TextField = styled(TextFieldStyle)`

`
export const FormHelperText = styled(FormHelperTextStyle)`

`
export const RadioGroup = styled(RadioGroupStyle)`
    && {
        row-gap: 4px;
        column-gap: 8px;
        margin-top: 6px;
        padding: 0 10px;
        &.message_options {
            padding: 0 10px;
            overflow-y: scroll;
            flex-wrap: nowrap;
        }
        display: flex;
        flex-direction: column;
        label {
            margin-right: 0;
            margin-left: 0;
            text-align: left;
            .label_radio {
                font-size: 14px;
            }
            .radio {
                padding: 3px;
            }
        }
    }
`
export const FormLabel = styled(FormLabelStyle)``
export const InputGroup = styled(FormControlStyle)`
    && {
        display: flex;
        flex-direction: row;
        &.fieldset {
            background-color: #ffffff;
            legend {
                color: #000000;
                letter-spacing: 0;
                font-size: 14px;
                padding: 0 16px 9px;
                text-align: start;
            }
            >div {
                margin-top: 0;
                padding: 0 12px;
            }
        }
        &+&&{
            margin-top: 16px;
        }
        >* {
            flex: 3 0;
            &+*:not(${RadioGroup}) {
                margin-left: 0;    
            }
            &.small{
                flex: 1 0;
            } 
        }
    }
`