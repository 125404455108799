import styled, {createGlobalStyle} from "styled-components";
import ButtonStyle from "@material-ui/core/Button";
import IconButtonStyle from "@material-ui/core/IconButton";
import MontserratRegular from "assets/fonts/Montserrat-Regular.ttf"
import MontserratBold from "assets/fonts/Montserrat-Bold.ttf"
export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Montserrat";
        font-weight: normal;
        src: url('https://chiesi-chat.digitalk.com.br/static/media/Montserrat-Regular.3cd78665.ttf') format('opentype');
    }
    
    @font-face {
        font-family: "Montserrat";
        font-weight: bold;
        src: url('https://chiesi-chat.digitalk.com.br/static/media/Montserrat-Bold.079ca05d.ttf') format('opentype');
    }
    #digitalk-chat {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Montserrat', sans-serif!important;
        font-size: 14px;
        z-index: 9999;
        * {
            font-family: 'Montserrat', sans-serif!important;
            z-index: 9999;
            p {
                text-align: start;
            }
        }
    }
    
    
    
    .hidden {
        display: none;
    }
`


export const Button = styled(ButtonStyle)`
    && {
        position: fixed;
        right: 32px;
        bottom: 32px;
        background-color: #B90066;
        color: #ffffff;
        padding: 9px 16px;
        width: unset;
        border-radius: 3px;
        span {
            font-size: 18px;
            text-wrap: none;
            text-transform: none;
            svg {
                margin-right: 11px;
                height: 20px;
            }
        }
    }
`

export const IconButton = styled(IconButtonStyle)`
    && {
        color: #ffffff;
        width: fit-content;
        svg {
            height: 13px;
        }
    }
`

export const Header = styled.div`
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 7px 5px 7px 17px;
    justify-content: space-between;
    background: url(${"https://chiesi-chat.digitalk.com.br/static/media/pink-pattern.1583818d.jpg"})  repeat, radial-gradient(69.06% 1675.41% at 30.94% 50.75%, rgba(185, 0, 102, 0.5) 23.98%, rgba(185, 0, 102, 0) 100%);
    background-size: 250px, 100%;
    >div {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        svg {
            margin-right: 10px;
            height: 20px;
        }
    }
`

export const Container = styled.div`
    width: 320px;
    max-width: 100%;
    border-radius: 5px;
    overflow: hidden;
    height: 548px;
    position: fixed;
    right: 32px;
    bottom: 32px;
    filter: drop-shadow(0px 30px 40px rgba(0, 0, 0, 0.15));
    @media(max-height: 540px) {
        bottom: 0;
        max-height: 100%;
    }
    @media(max-width: 500px) {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
        right: unset;
        bottom: 0;
    }
`

