import axios from 'axios'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_PATH}/api/v1`,
    headers: {'Content-Type': 'application/json'}
})


instance.interceptors.request.use(
    config => {
        //config.headers.Authorization = 'c7046e95-4be9-4d99-83fa-0b63b5f2cd9b' <- chiesi
        config.headers.Authorization = window.chtken
        return config
    },
    error => Promise.reject(error)
)

export default instance
