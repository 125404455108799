import io from 'socket.io-client'

const socket = io.connect(`${process.env.REACT_APP_API_PATH}`, {
    transports: ['websocket'],
    reconnection: true,
    autoConnect: false,
    reconnectionDelay: 1000,
    reconnectionDelayMax : 5000,
    reconnectionAttempts: Infinity
});

export default socket