import instance from './instance'

export function startProtocol() {
    return instance.get(`/protocol/start`)
}

export function getProtocol(id) {
    return instance.get(`/protocol/${id}`)
}

export function postForm(data) {
    return instance.post(`/form`, data)
}