import React from "react";
import {FilesContainer} from './style'
import {ReactComponent as Trash} from "assets/images/icon/trash.svg";

const Files = (props) => {
    return  <FilesContainer>
        {(props.file) && props.file.map((x, index) => <div key={`file-${index}`}>
            <div>{x.name.split('.')[x.name.split('.').length - 1]}</div>
            <div><p>{x.name}</p> <Trash
                onClick={() => props.remove(index)}/></div>
        </div>)}
    </FilesContainer>
}

export default Files