import {lazy} from 'react'










export default async (x) => {
  const x1 = await require(`../assets/icons/${x.pathname}/icon_x1.png`).default;
  const favicon = await require(`../assets/icons/${x.pathname}/favicon.ico`).default;
  const x72 = await require(`../assets/icons/${x.pathname}/icon_x72.png`).default;
  const x96 = await require(`../assets/icons/${x.pathname}/icon_x96.png`).default;
  const x128 = await require(`../assets/icons/${x.pathname}/icon_x128.png`).default;
  const x144 = await require(`../assets/icons/${x.pathname}/icon_x144.png`).default;
  const x152 = await require(`../assets/icons/${x.pathname}/icon_x152.png`).default;
  const x384 = await require(`../assets/icons/${x.pathname}/icon_x384.png`).default;
  const x512 = await require(`../assets/icons/${x.pathname}/icon_x512.png`).default;
  const dynamicManifest = {
    "name": x.name,
    "short_name": x.shortname,
    "icons": [
      {
        "src": x72,
        "type": "image/png",
        "sizes": "72x72",
        "purpose": "any"
      },
      {
        "src": x96,
        "type": "image/png",
        "sizes": "96x96",
        "purpose": "any"
      },
      {
        "src": x128,
        "type": "image/png",
        "sizes": "128x128",
        "purpose": "any"
      },
      {
        "src": x144,
        "type": "image/png",
        "sizes": "144x144",
        "purpose": "any"
      },
      {
        "src": x152,
        "type": "image/png",
        "sizes": "152x152",
        "purpose": "any"
      },
      {
        "src": x384,
        "type": "image/png",
        "sizes": "384x384",
        "purpose": "any"
      },
      {
        "src": x512,
        "type": "image/png",
        "sizes": "512x512",
        "purpose": "any"
      }
    ],
    "start_url": x.url,
    "display": "standalone",
    "theme_color": "#7d3ce8",
    "background_color": "#ffffff"
  }

  const stringManifest = JSON.stringify(dynamicManifest);
  const blob = new Blob([stringManifest], {type: 'application/json'});
  const manifestURL = URL.createObjectURL(blob);

  document.querySelector('[rel="icon"]').setAttribute('href', favicon);
  document.querySelector('[rel="manifest"]').setAttribute('href', manifestURL);
  document.querySelector('[rel="apple-touch-icon"]').setAttribute('href', x384);
}
