import styled from 'styled-components'
export const Container = styled.form`
    padding: 6px 16px;
    height: 40px;
    display: flex;
    background: #ffffff;
    border-top: 1px solid #BBBCBC;
    
    align-items: center;
    >input {
        flex: 1 0; 
        background-color: transparent;
        border: 0;
        outline: none;
        color: #122C62;
        caret-color: #2D6DF5;
    }
    >button {
        color: #2D6DF5;
        background-color: transparent;
        outline: none;
        border: 0;
        cursor: pointer;
        width: fit-content;
        &:disabled {
            color: #8d8d8d;
            cursor: not-allowed;
        }
        svg {
            height: 25px;
        }
    }
`

export const InputFile = styled.input`
    display: none;
    &+label {
        margin-right: 10px;
        svg {
            height: 18px;
        }
    }
`
