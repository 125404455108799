import styled,{keyframes} from 'styled-components'
import Scrollbars from 'react-custom-scrollbars'
const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    height: calc(100% - 54px);
    max-height: calc(100% - 54px);
    min-height: 494px;
    font-size: 14px;
    color: #000000;
    background-color: #ffffff;
`
export const MessageContainer = styled.ul`
    width: 100%;
    
    flex: 1 0;
    max-height: 100%;
    overflow-y: scroll;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    list-style: none;
    box-sizing: border-box;
    
            &:nth-child(1){
                margin-top: 0;
            }
    >ul {
        padding: 0 20px;
        list-style: none;
        margin: 20px 0!important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        >li {
        }
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    margin: -8px 0 0;
    padding: 0 12px 12px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    &.center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    >button {
        padding: 10px 12px;
        background: #5588a4;
        border: none;
        color: #ffffff;
        outline: none;
        cursor: pointer;
        border-radius: 3px;
        margin: 8px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-size: 14px;
        width: fit-content;
        line-height: 16px;
        svg {
            height: 18px;
            margin-right: 6px;
        }
    }
`

