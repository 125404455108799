import {useState, useContext} from 'react'
import {Container, InputFile} from './style'
import {ReactComponent as Send} from 'assets/images/icon/send.svg';
import {ReactComponent as Clip} from 'assets/images/icon/clip.svg';
import WidgetContext from "component/widget/context";
import Files from "component/chat/file";
import {postMessage} from "api/message";
import classNames from 'classnames'
import moment from 'moment'

const Input = (props) => {
    const {chat} = useContext(WidgetContext)

    const onSendMessage = async (e) => {
        e.preventDefault()

        const formData = new FormData()
        chat.message !== '' && formData.append('message', chat.message)
        formData.append('id_protocol', chat.id)
        chat.file.map(file => formData.append('file', file))

        let message = await postMessage(formData)
        const messages = []
        chat.file.map(file => {

            messages.push({
                created_at: moment().format('DD/MM/YYYY HH:mm:ss'),
                file: window.URL.createObjectURL(file),
                source: "customer",
                type: file.type
            })
        })
        chat.message !== '' && messages.push({
            source: 'customer',
            body: chat.message
        })

        chat.setChat({
            message: "", file: [], messages: [...chat.messages, ...messages]
        })
    }

    const onRemoveFile = (index) => {
        chat.setChat({file: chat.file.filter((x, i) => i !== index)})
    }


    return <>
        <Files file={chat.file} remove={onRemoveFile}/>
        <Container onSubmit={onSendMessage}>
            <InputFile type='file' id='file' name="file"
                       onChange={({target}) => chat.setChat({file: [...chat.file, ...target.files]})}/>
            {/*<label htmlFor="file" className='clip'>
                <Clip/>
            </label>*/}
            <input onKeyPress={e => (e.key === 'Enter') && onSendMessage(e)} type="text" placeholder="Digite sua mensagem"
                   value={chat.message} onChange={({target}) => chat.setChat({message: target.value})}/>
            <button disabled={(chat.message.trim() === '')}><Send/></button>
        </Container>
    </>
}

Input.defaultProps = {}

export default Input