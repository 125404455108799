import React, {useState, Suspense} from 'react'
import {Container, Form, ButtonContainer, Button, Box, InputGroup, RadioGroup, FormControl, OutlinedInput, InputLabel, FormHelperText, Select, FormLabel} from "./styled";
import InputMask from 'react-input-mask';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import useStateCallback from 'utils/useStateCallback'
import {ReactComponent as ArrowUpIcon} from "assets/images/icon/arrow-up.svg"
import WidgetComponent from "../widget"
import LinearProgress from '@material-ui/core/LinearProgress';
import {Main} from '../chat/style'
const FormInitState = {
    name: {value: '', error: ''},
    phone: {value: '', error: ''},
    email: {value: '', error: ''},
    customer_cpfcnpj: {value: '', error: ''},
    state: {value: '', error: ''},
    department: {value: null, error: ''},
}

const states = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"]
const type = [{label: "Consumidor", id: 1}, {label: "Farmácia", id: 2}, {label: "Farmacêutico", id: 7}, {label: "Médico", id: 4}, {label: "Hospital", id: 8}, {label: "Representante", id: 9}, {label: "Profissional da saúde", id: 5}, {label: "Distribuidor", id: 3}, {label: "Empresa pública/privada", id: 6}, {label: "Outros", id: 10}]

const AccessForm = (props) => {
    const [terms, setTerms] = useState(null)
    const [form, setForm] = useStateCallback({...FormInitState})
    const [chat, setChat] = useState(false)

    const handleChange = (e) => {
        const {value, name, type} = e.target
        if (name === "department") {
            setForm({...FormInitState})
        }
        switch (name) {
            case "department":
                setForm(prevState => ({
                    ...FormInitState,
                    [name]: {...prevState[name], value: Number.isInteger(value) ? Number(value) : value}
                }))
                break
            default:
                setForm(prevState => ({
                    ...prevState,
                    [name]: type === "checkbox" ? {
                        ...prevState[name],
                        value: !prevState[name].value
                    } : {...prevState[name], value}
                }))
                break;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setForm(prevState => form.department.value === 7 || form.department.value === 4 ? ({
            ...prevState,
            name: {...prevState.name, error: form.name.value.length === 0 ? "Campo obrigatório" : ""},
            phone: {...prevState.phone, error: form.phone.value.length === 0 ? "Campo obrigatório" : ""},
            state: {...prevState.state, error: form.state.value.length === 0 ? "Campo obrigatório" : ""},
            customer_cpfcnpj: {
                ...prevState.customer_cpfcnpj,
                error: form.customer_cpfcnpj.value.length === 0 ? "Campo obrigatório" : ""
            },
            email: {...prevState.email, error: form.email.value.length === 0 ? "Campo obrigatório" : ""}
        }) : ({
            ...prevState,
            name: {...prevState.name, error: form.name.value.length === 0 ? "Campo obrigatório" : ""},
            phone: {...prevState.phone, error: form.phone.value.length === 0 ? "Campo obrigatório" : ""},
            email: {...prevState.email, error: form.email.value.length === 0 ? "Campo obrigatório" : ""}
        }), (currForm) => {
            const value = Object.keys(currForm).reduce((prev, curr) => {
                prev[curr] = typeof currForm[curr].value === 'string' ? currForm[curr].value.trim() : currForm[curr].value
                !prev.error && (prev.error = currForm[curr].error.length > 0)
                return prev
            }, {})

            if (!value.error) {
                delete value.error
                setChat(value)
            }
        })
    }

    const getWelcome = () => {
        const hour = new Date().getHours();
        return ((hour<12 && "Bom dia") || (hour<18 && "Boa tarde") || "Boa noite")
    }

    return !chat ? <Container>
        {typeof terms !== "boolean" ? <>
            <Box>
                <p className="title">{getWelcome()}! Você está falando com o atendimento eletrônico do laboratório
                    Chiesi.</p>
                <p>Durante o contato, alguns de seus dados pessoais poderão ser coletados para que possamos te atender e
                    cumprir as exigências legais e regulatórias.
                    Esses dados são utilizados e armazenados de forma segura conforme previsto na Lei Geral de Proteção
                    de Dados.</p>
                <p>Ao dar continuidade a este atendimento, você declara estar ciente destes termos. Em caso de dúvidas,
                    entre em contato com dpo-br@chiesi.com.</p>
                <p className="confirm">Você deseja dar continuidade ao atendimento?</p>
            </Box>
            <Form>
                <ButtonContainer>
                    <Button type="button" className="exit" onClick={() => props.handleClose()}>Sair</Button>
                    <Button type="button" className="confirm" onClick={() => setTerms(true)}>Continuar</Button>
                </ButtonContainer>
            </Form>
        </> : (!terms ? <>
            <Box>
                <p className="title">Infelizmente não posso dar continuidade no atendimento.</p>
                <p>Para mais informações entre em contato no telefone 0800 1104525 ou no email cientifico@chiesi.com
                    Obrigado!</p>
            </Box>
            <Button type="button" className="back" onClick={() => setTerms(null)}>Voltar</Button>
        </> : <>
            <Form onSubmit={handleSubmit} className="form_container">
                {!form.department.value ? <div>
                    <p className="title">Antes de começarmos o atendimento, preciso de sua identificação.</p>
                    <InputGroup component="fieldset" classes={{root: 'fieldset'}}>
                    <FormLabel component="legend">Por favor me informe se você é:</FormLabel>
                    <RadioGroup onChange={handleChange} row aria-label="department" name="department"
                                value={form.department.value}>
                        {type.map(t => <FormControlLabel key={`type_${t.id}`} classes={{label: 'label_radio'}} value={t.id}
                                                             control={<Radio size="small" classes={{root: "radio"}} color="secondary"/>}
                                                             label={t.label}/>)}
                    </RadioGroup>
                </InputGroup></div> : <div>
                    <Button type="button" className="backDepartment" onClick={() => handleChange({target: {name: 'department'}})}><ArrowUpIcon/>Voltar</Button>
                    <p className="title">{type.find(x => x.id === Number(form.department.value)).label}, responda o formulário abaixo para iniciar a conversa!</p>
                    <FormControl color="secondary">
                        <InputLabel shrink={true} error={form.name.error.trim() !== ''} htmlFor="name">Nome</InputLabel>
                        <OutlinedInput name="name"  id="name" placeholder="Seu nome" error={form.name.error.trim() !== ''} onChange={handleChange} value={form.name.value}/>
                        <FormHelperText error={form.name.error.trim() !== ''}>{form.name.error}</FormHelperText>
                    </FormControl>
                    {(Number(form.department.value) === 7 || Number(form.department.value) === 4) && <InputGroup>
                            <FormControl classes={{root: 'select'}} variant="outlined" color="secondary">
                                <InputLabel id="state" error={form.state.error.trim() !== ''} shrink={true}>Estado</InputLabel>
                                <Select
                                    native
                                    labelId="state"
                                    id="state"
                                    placeholder="Seu estado"
                                    value={form.state.value}
                                    error={form.state.error.trim() !== ''}
                                    name="state"
                                    renderValue={selected =>
                                        <em>{(!selected || selected === "") ? "Seu estado" : selected}</em>}
                                    onChange={handleChange}
                                >
                                    <option aria-label="None" value="" disabled={true}/>
                                    {states.map(x => <option value={x}>{x}</option>)}
                                </Select>
                                {form.state.error.length > 0 &&
                                <FormHelperText error={form.state.error.trim() !== ''}>{form.state.error}</FormHelperText>}
                            </FormControl>
                            <FormControl color="secondary">
                                <InputLabel shrink={true} error={form.customer_cpfcnpj.error.trim() !== ''} htmlFor="customer_cpfcnpj">{Number(form.department.value) === 7 ? "CRF" : "CRM"}</InputLabel>
                                <OutlinedInput name="customer_cpfcnpj" id="customer_cpfcnpj" error={form.customer_cpfcnpj.error.trim() !== ''} onChange={handleChange} value={form.customer_cpfcnpj.value}/>
                                <FormHelperText error={form.customer_cpfcnpj.error.trim() !== ''}>{form.customer_cpfcnpj.error}</FormHelperText>
                            </FormControl>
                    </InputGroup>}
                    <FormControl className="phone" color="secondary">
                        <InputLabel shrink={true} error={form.phone.error.trim() !== ''} htmlFor="phone">Telefone</InputLabel>
                        <InputMask
                            mask={form.phone.value.match(/\d/g) && form.phone.value.match(/\d/g).join('').length === 11 ? "(99) 99999-9999" : "(99) 9999-99999"}
                            value={form.phone.value}
                            error={form.phone.error.trim() !== ''}
                            helperText={form.phone.error.trim() !== ''}
                            maskChar={form.phone.value.match(/\d/g) && form.phone.value.match(/\d/g).join('').length === 10 ? "" : " "}
                            onChange={handleChange}
                            name='phone'>
                            {() => <OutlinedInput name="phone" id="phone" placeholder="(  )      -    "/>}
                        </InputMask>
                        <FormHelperText error={form.phone.error.trim() !== ''}>{form.phone.error}</FormHelperText>
                    </FormControl>
                    <FormControl color="secondary">
                        <InputLabel shrink={true} error={form.email.error.trim() !== ''} htmlFor="email">E-mail</InputLabel>
                        <OutlinedInput name="email" id="email" placeholder="email@email.com" error={form.email.error.trim() !== ''} onChange={handleChange} value={form.email.value}/>
                        <FormHelperText error={form.email.error.trim() !== ''}>{form.email.error}</FormHelperText>
                    </FormControl>
                </div>}
                {form.department.value && <Button disabled={(form.email.value.trim() === '' || form.phone.value.trim() === '' || form.name.value.trim() === '') || ((Number(form.department.value) === 7 || Number(form.department.value) === 4) && (form.state.value.trim() === '' || form.customer_cpfcnpj.value.trim() === '')) } className="confirm" type="submit">INICIAR CONVERSA</Button>}
            </Form>
        </>)}
    </Container> : <WidgetComponent form={chat} {...props}/>

}

export default AccessForm