import ChiesiColor from 'assets/images/brands/CHIESI-01.png'
import ChiesiWhite from 'assets/images/brands/CHIESI-02.png'
export const Wallet = {
    chiesi: {
        name: "Chiesi",
        shortname: "Chiesi",
        logo_color: ChiesiColor,
        token: 'c7046e95-4be9-4d99-83fa-0b63b5f2cd9b',
        logo_white: ChiesiWhite,
        color: {
            primary: "#00a0e3",
            secondary: ""
        }
    },
}