import {useEffect, useState, useRef} from 'react'
import Chat from 'component/chat'
import NPS from 'component/nps'
import socket from 'api/socket'
import WidgetContext from './context'
import {getProtocol, startProtocol, postForm} from 'api/chat'


const Widget = (props) => {
    const [chat, setChat] = useState({
        id: null,
        closed: false,
        message: '',
        file: [],
        messages: [],
        options: [],
        nps: false
    })
    const MessagesRef = useRef(null)

    const closedEvent = () => {
        setChat(prevState => ({...prevState, closed: true}))
    }

    const NPSEvent = (e) => {
        setChat(prevState => ({
            ...prevState, nps: {
                ...e
            }
        }))
    }

    const messageEvent = (data) => {
        localStorage.setItem('chatOptions', null)
        setChat(prevState => ({...prevState, messages: [...prevState.messages, data]}))
    }

    const OptionsEvent = (data) => {
        localStorage.setItem('chatOptions', data.body)
        setChat(prevState => ({...prevState, options: JSON.parse(data.body)}))
    }


    useEffect(() => {
        const fetchNewProtocol = async () => {
            const newProtocol = await postForm(props.form)
            //const start = await startProtocol()

            localStorage.setItem('chat', JSON.stringify({
                protocol: newProtocol.data.protocol_id,
                createdAt: new Date()
            }));

            const protocol = await getProtocol(newProtocol.data.protocol_id)
            socket.emit('join', newProtocol.data.protocol_id)
            setTimeout(async () => {
                setChat(prevState => ({...prevState, ...protocol.data}))
            }, 2600)
        }
        socket.connected && socket.disconnect()
        socket.io.opts.query = {
            token: window.chtken,
        };
        socket.connect()
        fetchNewProtocol();


        socket.on('closed', closedEvent)
        socket.on('start_nps', NPSEvent)
        socket.on('addMessage', messageEvent)
        socket.on('showOptions', OptionsEvent)
        return () => {
            socket.off('closed', closedEvent)
            socket.off('start_nps', NPSEvent)
            socket.off('addMessage', messageEvent)
            socket.off('showOptions', OptionsEvent)
        }
    }, [])
    return  <WidgetContext.Provider value={{
        chat: {...chat, setChat: (e) => setChat(prevState => ({...prevState, ...e}))}
    }}>
        {(chat.nps && !chat.closed) ? <NPS/> : <Chat/>}
    </WidgetContext.Provider>
}

export default Widget