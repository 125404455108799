import React, {useEffect, useState} from 'react'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {GlobalStyle, Button, Header, IconButton, Container} from './style'
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {ReactComponent as ChiesiLogo} from 'assets/images/chiesi.svg'
import {ReactComponent as CloseIcon} from 'assets/images/icon/close.svg'
import Form from "component/form";
import {Wallet} from "config"
import GenerateManifest from "./generate_manifest";
import PinkPatternImage from 'assets/images/pink-pattern.jpg';
const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#B90066",
        },
        secondary: {
            main: "#5489A3"
        }
    },
})

function App() {
    const pathname = 'chiesi'
    const currWallet = Wallet['chiesi']
    const [open, setOpen] = useState(false)
    useEffect(() => {
        localStorage.clear()
        GenerateManifest({...currWallet, pathname, url: window.location.href})
        window.chtken = currWallet.token;
    }, [])

    return <ThemeProvider theme={theme}>
        <GlobalStyle/>
        {!open ? <Button variant="contained" color="primary" onClick={() => setOpen(true)}>Chat online</Button> :
            <Container>
                <Header imgURL={PinkPatternImage}>
                    <div>Chat online</div>
                    <IconButton onClick={() => setOpen(false)}><CloseIcon/></IconButton>
                </Header>
                <Form pathname={pathname} wallet={currWallet} handleClose={() => setOpen(false)}/>
            </Container>}


        <ToastContainer position="bottom-right"
                        autoClose={1500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>
    </ThemeProvider>
}

export default App
